<template>
  <div class="list-page">
  <van-list
      class="record-list"
      :finished="page===0"
      :finished-text="familyRecord && familyRecord.length ? '没有更多了' : ''"
      offset="10"
      @load="getPurchaseRecord"
    >
    <!-- <div class="record-list"> -->
      <template v-if="familyRecord && familyRecord.length">
        <div class="item" v-for="item in familyRecord" :key="item.orderNo">
          <div class="order-no">订单号：{{ item.order_no }}</div>
          <div class="line"></div>
          <van-row type="flex" justify="space-between">
            <div class="left">
              <div class="name">{{ `${item.name} ${item.phone}` }}</div>
              <div class="time">购买日期：{{ formatTimestamp(item.create_time, 'YYYY.MM.DD') }}</div>
            </div>

            <div class="right amount">金额:<span>¥{{ convertCentsToDollars(item.amount) }}</span></div>
          </van-row>
        </div>
      </template>
      <div v-else-if="!loading && (!familyRecord || !familyRecord.length)" class="list-empty">
        <div class="text">
          暂无数据内容～
        </div>
      </div>
    <!-- </div> -->
  </van-list>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast,List } from 'vant';
import { userPurchaseRecord } from '@/apis/user';
import { formatTimestamp } from '@/utils/time'

Vue.use(List);

export default {
  name: "JoinFamilyRecord",
  props: {
  },
  data() {
    return {
      familyRecord:[],
      page: 1,
      count: 6,
      loading: false,
    }
  },
  methods: {
    formatTimestamp: formatTimestamp,
    async getPurchaseRecord() {
      if (this.page === 0 || this.loading) return;
      Toast.loading();
      this.loading = true;
      try {
        const ret = await userPurchaseRecord(this.page, this.count);
        if (ret.data.list && ret.data.list.length) {
          this.$set(this, 'familyRecord', this.familyRecord.concat(ret.data.list));
        }
        this.page = ret.data.next;
      } catch (error) {
        console.log('活动参与记录加载出错', error)
      } finally {
        this.loading = false;
        Toast.clear();
      }
    },
    convertCentsToDollars(cents) {
      // 将分转换为元，并保留两位小数
      const dollars = (cents / 100).toFixed(2);
      return dollars;
    }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.list-page{
  // height: 100%;
  background-color: #F7F7F7;
  box-sizing: border-box;
  height: calc(100vh - 88px);
  overflow: scroll;
}

.record-list{
  padding-top: 20px;
  .item {
    width: 704px;
    height: 234px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 20px;
    padding-top: 22px;
    .order-no {
      // width: 287px;
      height: 37px;
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 37px;
      margin-left: 39px;
    }

    .line {
      width: 642px;
      height: 1px;
      background-color: #E5E5E5;
      margin: auto;
      margin-top: 15px;
    }
    .left{
      margin-left: 38px;
      margin-top: 26px;
      .name{
        height: 48px;
        font-size: 34px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 48px;
      }
      .time{
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666660;
        line-height: 37px;
        margin-top: 18px;
      }
    }

    .amount{
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
        span{
          color: #000000;
          font-weight: 600;
        }
      }
    .right{
      width: 150px;
      height: 176px;
      border-radius: 9px;
      margin-top: 92px;
      margin-right: 29px;
      overflow: hidden;
    }
  }

  .list-empty{
    position: relative;
    width: 564px;
    height: 564px;
    background: url('../assets/list-empty.png') no-repeat;
    background-size: 564px;
    margin: auto;
    margin-top: 104px;
    .text{
      position: absolute;
      width: 100%;
      bottom: 23px;
      text-align: center;
      margin: auto;
      height: 37px;
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 37px;
    }
  }
}
</style>
