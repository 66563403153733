<template>
  <div class="page">
    <van-tabs v-model="active" swipeable 
      line-width="2.346rem" line-height="0.267rem"
      title-active-color="#000000">
      <van-tab title="活动记录"><JoinActivityRecord/></van-tab>
      <van-tab title="购买记录"><JoinFamilyRecord/></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tab, Tabs } from 'vant';
import JoinActivityRecord from '@/components/JoinActivityRecord.vue';
import JoinFamilyRecord from '@/components/JoinFamilyRecord.vue';

Vue.use(Tab);
Vue.use(Tabs);
export default {
  name: "RecordPage",
  components: {
    JoinActivityRecord,
    JoinFamilyRecord
  },
  props: {
  },
  data() {
    return {
      active : 0
    }
  },
  mounted(){
    if(this.$route.query.tab) {
      console.log("canshu", this.$route.query.tab);
      this.$nextTick(() => {
        this.active = +this.$route.query.tab;
      })
      
    }
  },
  methods: {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page{
  height: 100%;
  /* padding-bottom: 49px; */
}

/deep/ .van-tabs__line{
  border-radius: 0 !important;
}
</style>
