<template>
  <div class="page">
    <template v-if="!payDone">
      <div class="head">
        <img class="prize-desc-title" src="../assets/join-family/prize-desc.png" />
        <div class="prize-desc">
          洛宝贝吉祥龙年手机屏幕壁纸；<br>
          洛宝贝吉祥红包封面；<br>
          洛宝贝粉爱你海报；<br>
          洛宝贝生活问候表情包3弹36个；<br>
          二十四节气问候祝福海报。<br>
          享受全年健康沙龙课堂12次，每月1次，每次限30人（限北京）；<br>
          享受四季健康义诊四次，每次限15人；<br>
          （限北京）
        </div>
        <div class="form">
          <img class="title" src="../assets/join-family/join-info.png" />
          <div class="container">
            <div class="container-dotted">
              <div class="input input-name">
                <van-field v-model="name" maxlength="8" placeholder="姓名" />
              </div>
              <div class="input input-phone">
                <van-field v-model="phone" type="tel" placeholder="手机号" />
              </div>

            </div>
          </div>
          <div class="join-btn" @click="joinFamily"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pay-success">
        <div class="luo-love-you"></div>
        <van-row type="flex" align="center" justify="center">
          <img class="pay-success-icon" src="../assets/icon-success.png" />
          <div class="title">提交成功</div>
        </van-row>
        <div class="amount">￥{{ amount }}</div>
        <van-row type="flex" justify="space-between">
          <div class="back-home" @click="backHome">返回首页</div>
          <div class="record-list" @click="goToRecord">查看记录</div>
        </van-row>
        <template v-if="qrcode">
          <div class="qr-code-container">
            <img :src="qrcode" />
          </div>
          <div class="tips">长按扫一扫添加微信，了解最新活动进展</div>
        </template>
      </div>

    </template>
  </div>
</template>
<script>
import Vue from 'vue';
import { Field, Toast } from 'vant';
import { joinFamily } from '@/apis/user'
import { getAppConfig } from "@/apis/app";
import wx from "weixin-js-sdk";

Vue.use(Field);
export default {
  name: "JoinFamily",
  props: {
  },
  data() {
    return {
      name: '',
      phone: '',
      payDone: false,
      qrcode: '',
      amount: 0,
    }
  },
  methods: {
    async joinFamily() {
      if (!this.name) {
        Toast('请填写姓名');
        return;
      }
      if (!this.phone) {
        Toast('请填写手机号');
        return;
      }
      const namePattern = /^[A-Za-z\u4e00-\u9fa5]+$/;
      // 使用正则表达式测试输入字符串
      if (!namePattern.test(this.name)) {
        Toast('姓名只能为中文或字母');
        return;
      }

      // 中国大陆手机号正则表达式
      const phonePattern = /^1[3456789]\d{9}$/;
      // 使用正则表达式测试手机号
      if (!phonePattern.test(this.phone)) {
        Toast('请填写正确的手机号码');
        return;
      }
      const ret = await joinFamily(this.name, this.phone);
      console.log('支付', ret);
      if (ret.data.payment) {
        wx.chooseWXPay({
          ...ret.data.payment,
          success: (res)=> {
            console.log('前端支付结果', res)
            if (res.errMsg === "chooseWXPay:ok") {
              getAppConfig().then(ret => {
                this.qrcode = ret.data.customer_service_wechat
              });
              this.payDone = true;
            }
          }
        });
        this.amount = (ret.data.amount / 100).toFixed(2);
      }
    },

    backHome() {
      this.$router.push('/');
    },

    goToRecord() {
      this.$router.push('/record?tab=1');
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.page {
  min-height: 100%;
  // background: url('https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/bg.png') repeat;
  // background-size: 100%;
  // padding-top: 30px;
  padding-bottom: 49px;
}

.head {
  width: 750px;
  height: 1551px;
  background: url('../assets/join-family/toutu.png') no-repeat;
  background-size: 750px 1551px;
  padding-top: 554px;
  padding-left: 21px;
  box-sizing: border-box;

  .prize-desc-title {
    width: 141px;
    height: 33px;
  }

  .prize-desc {
    width: 600px;
    height: 360px;
    font-size: 26px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    margin-top: 30px;
  }
}

.form {
  margin-left: -21px;
  margin-top: 37px;

  .title {
    width: 142px;
    height: 34px;
    margin-left: 21px;
  }

  .container {
    width: 672px;
    height: 293px;
    background: #FFBEBE;
    border-radius: 12px;
    margin: auto;
    margin-top: 31px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .container-dotted {
      width: 614px;
      height: 242px;
      border-radius: 12px;
      border: 2px dashed #FE6262;
      margin: auto;
      margin-top: 23px;
    }

    .input {
      width: 537px;
      height: 80px;
      background: #FFFFFF;
      border-radius: 12px;
      border: 1px solid #FFB58F;
      margin: auto;
      overflow: hidden;
    }

    .input-name {
      margin-top: 22px;
    }

    .input-phone {
      margin-top: 30px;
    }
  }

  .join-btn {
    width: 450px;
    height: 104px;
    background: url('../assets/join-family/join-btn.png') no-repeat;
    background-size: 450px 104px;
    margin: auto;
    margin-top: 17px;
  }
}

.pay-success {
  padding-top: 51px;

  .luo-love-you{
    width: 369px;
    height: 82px;
    background: url('../assets/luo-love-you.png') no-repeat;
    background-size: 369px 82px;
    margin: auto;
    margin-bottom: 35px;
  }

  .pay-success-icon {
    width: 51px;
    height: 48px;
  }

  .title {
    height: 48px;
    font-size: 34px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 48px;
    margin-left: 8px;
  }

  .amount {
    height: 78px;
    font-size: 56px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 78px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 53px;
  }

  .back-home {
    width: 268px;
    height: 84px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #979797;
    font-size: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 84px;
    margin-left: 87px;
  }

  .record-list {
    width: 268px;
    height: 84px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #000000;
    font-size: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    line-height: 84px;
    margin-right: 87px;
  }

  .qr-code-container {
    width: 350px;
    height: 350px;
    border-radius: 17px;
    border: 1px solid #D7D7D7;
    margin: auto;
    margin-top: 111px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 328px;
      height: 328px;
    }
  }

  .tips {
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 37px;
    text-align: center;
    margin: auto;
    margin-top: 40px;
  }
}
</style>
