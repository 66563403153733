import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Toast } from 'vant'
import { Col, Row } from 'vant';
import { Image as VanImage } from 'vant';
import { Lazyload } from 'vant';


import './utils/rem.js'
import 'vant/lib/index.less';


Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Lazyload);
Toast.allowMultiple();

// import VConsole from 'vconsole';
// new VConsole();

Vue.config.productionTip = false

console.log('环境！！', process.env);



new Vue({
  render: h => h(App),
  router
}).$mount('#app')
