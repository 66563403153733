import { get, post, upload } from '../utils/request';

export const login = (code) => {
    return post('/api/user/login', { code })
};


export const uploadImage = (file) => {
    // 创建 FormData 对象
    const formData = new FormData();
    // 添加要上传的文件到 FormData 对象中
    formData.append('image', file);
    return upload('/api/image/upload', formData)
}

export const joinFamily = (name, phone) => {
    return post('/api/family/join', { name, phone })
};


export const joinActivity = (name, phone, image) => {
    return post('/api/activity/join', { name, phone, image })
};

export const activityRecord = (page, count) => {
    return get('/api/activity/record', { page, count })
};

export const userActivityRecord = (page, count) => {
    return get('/api/user/activity/record', { page, count })
};

export const userPurchaseRecord = (page, count) => {
    return get('/api/user/purchase/record', { page, count })
};

