export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function removeParameterByName(name, url) {
    if (!url) url = window.location.href;
    var urlParts = url.split('?');
    if (urlParts.length >= 2) {
        var prefix = encodeURIComponent(name) + '=';
        var parts = urlParts[1].split(/[&;]/g);
  
        // 从参数数组中删除指定名称的参数
        for (var i = parts.length; i-- > 0;) {
            if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                parts.splice(i, 1);
            }
        }
  
        // 重新构建 URL
        url = urlParts[0] + (parts.length > 0 ? '?' + parts.join('&') : '');
        return url;
    } else {
        return url;
    }
  }