import axios from 'axios';
import { HmacSHA1 } from 'crypto-js';
import { Toast } from 'vant';
import { getJssdkConfig } from "@/apis/app";

/**
 * 获取当前时间戳
 */
const getTime = function() {
    var d = new Date();
    var time = d.getTime() / 1000;
    return parseInt(time);
  }

/**
 * url编码，解决encodeURIComponent函数不编码部分字符的缺陷
 */
const urlEncode = function (str) {
    let encode = encodeURIComponent(str);
    return encode.replace('(', '%28')
        .replace(')', '%29')
        .replace('!', '%21')
        .replace('~', '%7E')
        .replace('*', '%2A')
        .replace('\'', '%27');
}

/**
 * 排序的函数，根据键值升序排序
 */
const objKeySort = function (obj) {
    var newkey = Object.keys(obj).sort();
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) { //遍历newkey数组
        newObj[newkey[i]] = obj[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj; //返回排好序的新对象
}

/**
 * 获取api请求sign
 */
const getSign = function (params, api) {
    let reqdata = params || {};
    reqdata = objKeySort(reqdata);
    for (let key in reqdata) {
        api = api + "&" + key + "=" + reqdata[key];
    }
    // console.log(' 拼接好的数据 =' + api);
    const key = "21fa640071be75771e7647588f709a53";
    // console.log(' 编码后 =' + urlEncode(api));
    const sha1_result = HmacSHA1(urlEncode(api), key); //第一个参数为加密字符串，第二个参数为公共秘钥
    // console.log(' 客户端返回的sign =' + sha1_result);
    return sha1_result + "";
}

// 创建 Axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 设置基础URL
    timeout: 10000, // 请求超时时间
});

// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        console.log('config', config)
        if(config.method == 'post') {
            if(config.data) {
                config.data.time = getTime();
            }else{
                config.data =  { time: getTime()};
            }
            const { data, url } = config;
            config.data.sign = getSign(data, url);
        }
        if(config.method == 'get') {
            if(config.params) {
                config.params.time = getTime();
            }else{
                config.params =  { time: getTime()};
            }
            const { params, url } = config;
            config.params.sign = getSign(params, url);
        }
        // 在发送请求之前做些什么
        // 例如，可以在此处设置统一的请求头
        config.headers['ut'] = localStorage.getItem('userToken');
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        switch(response.data.code) {
            case 0:
                break;
            case 2002:
                getJssdkConfig(window.location.href).then(async (ret) => {
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${ret["data"]['appId']}&redirect_uri=${encodeURIComponent(ret["data"]['url'])}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                });
                break;
            default:
                Toast(response.data.message);
                break;
        }
        // 对响应数据做点什么
        return response.data;
    },
    function (error) {
        // 对响应错误做点什么
        // 例如，可以在此处统一处理错误信息
        console.error('Request failed:', error);
        return Promise.reject(error);
    }
);

// 封装 GET 请求
export const get = (url, params) => {
    return instance.get(url, {
        params
    });
};

// 封装 POST 请求
export const post = (url, data) => {
    return instance.post(url, data);
};

export const upload = async (url, formData) => {
    try {
      // 发送 POST 请求
      const response = await instance.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // 设置请求头为 multipart/form-data
        }
      });
      // 返回上传成功的响应数据
      return response.data;
    } catch (error) {
      // 处理上传失败的情况
      console.error('上传图片失败:', error);
      throw error; // 抛出错误，可供调用函数处理
    }
  }