<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getParameterByName, removeParameterByName } from "./utils/url.js"
import { login } from "./apis/user"
import { getJssdkConfig } from "./apis/app";

export default {
  name: "App",
  components: {},
  data() {
    return {
      loginUrl: '',
    }
  },
  methods: {
    async initJssdk() {
      try {
        wx.ready(async () => {
          wx.updateTimelineShareData({
            title: '洛宝贝粉爱您', // 分享标题
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/WechatIMG34.jpg', // 分享图标
            success: () => {
              // 设置成功
              console.log("分享朋友圈初始化成功");
            }
          })
          wx.updateAppMessageShareData({
            title: '洛宝贝粉爱您', // 分享标题
            desc: '成为自己和身边人的光', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/WechatIMG34.jpg', // 分享图标
            success: () => {
              // 设置成功
              console.log("分享好友初始化成功");
            }
          })
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
        getJssdkConfig(window.location.href).then(async (ret) => {
          this.loginUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${ret["data"]['appId']}&redirect_uri=${encodeURIComponent(ret["data"]['url'])}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
          wx.config(ret["data"]);
          await this.handleAuthCode();
          this.checkLogin();
        });
      } catch (error) {
        console.log(error);
      }
    },

    async handleAuthCode() {
      const code = getParameterByName('code', window.location.href);
      if (code) {
        const ret = await login(code);
        localStorage.setItem('userToken', ret.data.token);
        history.pushState({}, '', removeParameterByName('code', window.location.href));
      }
    },

    checkLogin() {
      if (!localStorage.getItem('userToken') && this.loginUrl) {
        console.log("嘿嘿嘿/?", this.loginUrl);
        window.location.href = this.loginUrl + '';
      }
    }
  },
  async mounted() {
    await this.initJssdk();
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  height: 100%;
}

html,
body {
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}</style>
