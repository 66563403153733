<template>
  <div class="list-page">
    <van-list class="record-list" :finished="page === 0"
      :finished-text="activityRecord && activityRecord.length ? '没有更多了' : ''" offset="10" @load="getActivityRecord">
      <!-- <div class="record-list"> -->
      <template v-if="activityRecord && activityRecord.length">
        <div class="item" v-for="item in activityRecord" :key="item.id">
          <van-row type="flex" justify="space-between">
            <div class="left">
              <div class="name">{{ `${item.name} ${item.phone}` }}</div>
              <div class="time">参与日期：{{ formatTimestamp(item.create_time, 'YYYY.MM.DD') }}</div>
              <!-- <div class="amount">金额:<span>¥{{ item.amount }}</span></div> -->
            </div>
            <van-image class="right" :src="item.image" lazy-load fit="cover" />
          </van-row>
        </div>
      </template>
      <div v-else-if="!loading && (!activityRecord || !activityRecord.length)" class="list-empty">
        <div class="text">
          暂无数据内容～
        </div>
      </div>
      <!-- </div> -->
    </van-list>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, List } from 'vant';
import { userActivityRecord } from '@/apis/user';
import { formatTimestamp } from '@/utils/time'

Vue.use(List);
export default {
  name: "JoinActivityRecord",
  props: {
  },
  data() {
    return {
      activityRecord: [
        // {
        //   id:1,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:2,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:3,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:4,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:5,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:6,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:7,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // },
        // {
        //   id:8,
        //   name: '王小华 18291029101',
        //   time: '2024.01.13',
        //   amount: '1.00'
        // }
      ],
      page: 1,
      count: 6,
      loading: false,
    }
  },
  methods: {
    formatTimestamp: formatTimestamp,
    async getActivityRecord() {
      console.log('loading', this.loading)
      if (this.page === 0 || this.loading) return;
      Toast.loading();
      this.loading = true;
      try {
        const ret = await userActivityRecord(this.page, this.count);
        if (ret.data.list && ret.data.list.length) {
          this.$set(this, 'activityRecord', this.activityRecord.concat(ret.data.list));
        }
        this.page = ret.data.next;
      } catch (error) {
        console.log('活动参与记录加载出错', error)
      } finally {
        this.loading = false;
        Toast.clear();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.list-page {
  background-color: #F7F7F7;
  box-sizing: border-box;
  height: calc(100vh - 88px);
  overflow: scroll;
}

.record-list {
  padding-top: 20px;

  .item {
    width: 704px;
    height: 234px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 20px;

    .left {
      margin-left: 38px;
      margin-top: 28px;

      .name {
        height: 48px;
        font-size: 34px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 48px;
      }

      .time {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 37px;
        margin-top: 73px;
      }

      .amount {
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
        margin-top: 26px;

        span {
          color: #000000;
          font-weight: 600;
        }
      }
    }

    .right {
      width: 150px;
      height: 176px;
      border-radius: 9px;
      margin-top: 28px;
      margin-right: 33px;
      overflow: hidden;
    }
  }

  .list-empty {
    position: relative;
    width: 564px;
    height: 564px;
    background: url('../assets/list-empty.png') no-repeat;
    background-size: 564px;
    margin: auto;
    margin-top: 104px;

    .text {
      position: absolute;
      width: 100%;
      bottom: 23px;
      text-align: center;
      margin: auto;
      height: 37px;
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 37px;
    }
  }
}
</style>
