import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
import HomePage from "@/pages/HomePage.vue";
import JoinActivity from "@/pages/JoinActivity.vue";
import JoinFamily from "@/pages/JoinFamily.vue";
import RecordPage from "@/pages/RecordPage.vue";
 
Vue.use(Router)  //Vue全局使用Router
 
export default new Router({
  routes: [              //配置路由，这里是个数组
    {
        path:"/",
        component: HomePage
    },
    {
        path:"/join/activity",
        component: JoinActivity
    },
    {
      path:"/join/family",
      component: JoinFamily
    },
    {
      path:"/record",
      component: RecordPage
    },
    
    // {
    //   path:'/hi',
    //   component: Hi,
    //   children:[        //子路由,嵌套路由 （此处偷个懒，免得单独再列一点）
    //     {path:'/',component:Hi},
    //     {path:'hi1',component:Hi1},
    //     {path:'hi2',component:Hi2},
    //   ]
    // }
  ]
})
