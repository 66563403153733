<template>
  <div class="page">
    <div class="head">
      <div class="text-container">
        <div class="text">
          <span>「洛宝贝」</span>邀请大家在这段时间内，每天与身边的亲朋好友拥抱<span>10秒钟</span>
          ，感受温暖和爱意，并拍照记录这一温馨时刻！
        </div>
        <div class="add-btn">
          <img src="../assets/home/icon-date.png" />
          <div @click="joinFamily">加入亲友团</div>
        </div>
      </div>

      <div class="luo-love-you"></div>
    </div>

    
    <img class="act-time" src="../assets/home/act-time.png" />
    <div class="prize-show">
      <img class="title" src="../assets/home/prize-show-title.png">
      <div class="desc"><span>*</span>所有提交照片的参与者均获得12生肖吉祥祈福手机壁纸</div>
      <img class="prize-main-img" src="../assets/home/prize-main-img.png" />
    </div>
    <div class="photo-show">
      <div class="photo-list" ref="photoList" @scroll="handleScroll">
        <template v-if="photoList && photoList.length">
          <div v-for="item in photoList" :key="item.id" class="photo-item">
            <van-image class="main-img" fit="cover" :src="item.image" />
            <van-row type="flex" align="center">
              <img class="user-img" :src="item.avatar" />
              <div class="user-name">{{ item.name }}</div>
            </van-row>
          </div>
        </template>
        <div v-else class="photo-empty-container">
          <div class="photo-empty"></div>
          <div class="text">暂无数据内容～</div>
        </div>

      </div>
    </div>

    <div class="join-activity" @click="goToJoinActivity"></div>

    <div class="btn-group">
      <div class="btn-item" @click="shareActivity">
        分享活动
      </div>
      <div class="btn-item" @click="goToRecordPage">
        报名记录
      </div>
    </div>

    <!-- 分享窗口 -->
    <!-- <van-popup v-model="shareDialogShow" round position="bottom">
      <div class="share-dialog">
        <div class="title">分享至</div>
        <van-row type="flex" justify="space-around">
          <div class="share-item" style="margin-left: 1rem;" @click="updateAppMessageShareData">
            <img class="icon" src="../assets/icon-wechat.png" />
            <div class="text">微信</div>
          </div>
          <div class="share-item" style="margin-right: 1rem;" @click="updateTimelineShareData">
            <img class="icon" src="../assets/icon-moment.png" />
            <div class="text">朋友圈</div>
          </div>
        </van-row>

        <div class="icon-close" @click="shareDialogShow = false"></div>
      </div>
    </van-popup> -->

    <!-- 首屏活动弹窗 -->
    <van-popup v-model="actDialogShow" :close-on-click-overlay="false" :close-on-popstate="true"
      :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.84)' }">
      <div class="act-dialog">
        <img class="img" src="../assets/act-dialog.png" />
        <div class="close" @click="closeActDialog"></div>
      </div>
    </van-popup>

    <!-- 分享成功弹窗 -->
    <van-popup v-model="shareSuccessShow" round position="bottom">
      <div class="share-success">
        <div class="title"><img src="../assets/home/icon-success.png" />分享成功</div>
        <img class="main-img" src="https://p.ipic.vip/bybcci.png" />
        <div class="icon-close" @click="shareSuccessShow = false"></div>
      </div>
    </van-popup>

    <van-overlay :show="shareDialogShow" @click="shareDialogShow=false">
      <div class="share-guide-container">
        <div class="share-guide">
          <img class="i-konw" src="../assets/home/share-guide-btn.png">
        </div>
      </div>
    </van-overlay>
    <!-- <van-popup v-model="shareDialogShow"> -->
      
    <!-- </van-popup> -->

  </div>
</template>

<script>
import Vue from 'vue';
import { activityRecord } from '@/apis/user'
import { Toast, List, Dialog,Overlay } from 'vant';


Vue.use(Overlay);
Vue.use(Dialog);
Vue.use(List);

export default {
  name: "HomePage",
  props: {
  },
  data() {
    return {
      actDialogShow: true,
      shareDialogShow: false,
      shareSuccessShow: false,
      photoList: [],
      page: 1,
      count: 6,
      loading: false,
      shareStatus: false
    }
  },
  mounted() {
    // this.checkActDialog();
    this.getActivityRecord();
    document.addEventListener("visibilitychange", this.visibilitychange);
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.visibilitychange);
  },
  methods: {
    visibilitychange() {
      if (document.visibilityState === 'visible') {
        // 浏览器从后台切换到前台
        console.log('浏览器进入前台');
        if (this.shareStatus) {
          this.shareStatus = false;
          this.shareSuccessShow = true;
        }
      }
    },

    // checkActDialog() {
    //   const aleadyShow = localStorage.getItem('act-dialog-show');
    //   if (!aleadyShow) {
    //     this.actDialogShow = true;
    //   }
    // },
    async getActivityRecord() {
      if (this.page === 0 || this.loading) return;
      Toast.loading();
      this.loading = true;

      try {
        const ret = await activityRecord(this.page, this.count);
        if (ret.data.list && ret.data.list.length) {
          this.$set(this, 'photoList', this.photoList.concat(ret.data.list));
        }
        this.page = ret.data.next;
      } catch (error) {
        console.log('加载照片墙出错')
      } finally {
        this.loading = false;
        Toast.clear();
      }
    },
    // 滚动事件处理
    handleScroll() {
      const listContainer = this.$refs.photoList;
      // 滚动到底部，加载更多
      if (
        listContainer.scrollTop + listContainer.clientHeight >=
        listContainer.scrollHeight - 10
      ) {
        this.getActivityRecord()
      }
      // 检测是否在顶部或底部
      this.isAtTop = listContainer.scrollTop === 0;
      this.isAtBottom =
        listContainer.scrollTop + listContainer.clientHeight ===
        listContainer.scrollHeight;
    },
    closeActDialog() {
      this.actDialogShow = false;
      localStorage.setItem('act-dialog-show', true);
    },
    goToJoinActivity() {
      this.$router.push('/join/activity');
    },
    goToRecordPage() {
      this.$router.push('/record');
    },
    joinFamily() {
      this.$router.push('/join/family');
    },
    shareActivity() {
      this.shareDialogShow = true;
    },
    updateAppMessageShareData() {
      // this.shareStatus = true;
      this.shareDialogShow = false;
      // TODO 提示分享
      Dialog({ message: '请点击页面右上角进行分享' });
    },
    updateTimelineShareData() {
      this.shareStatus = true;
      this.shareDialogShow = false;
      //TODO 提示分享
      Dialog({ message: '请点击页面右上角进行分享' });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.page {
  min-height: 100%;
  background: url('https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/bg.png') repeat;
  background-size: 100%;
}

.head {
  position: relative;
  width: 750px;
  height: 494px;
  background: url('../assets/home/head.png') no-repeat;
  background-size: 750px 494px;

  .text-container {
    position: absolute;
    top: 93px;
    right: 58px;
    width: 303px;

    .text {
      width: 100%;
      height: 180px;
      font-size: 24px;
      font-weight: 400;
      font-family: PingFangSC, PingFang SC;
      color: #333333;
      line-height: 36px;

      span {
        font-weight: 600;
        color: #000000;
      }
    }

    .add-btn {
      width: 196px;
      height: 60px;
      border-radius: 30px;
      border: 2px solid #D5230A;
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #D5230A;
      justify-content: center;
      align-items: center;
      line-height: 33px;

      img {
        width: 37px;
        height: 37px;
        margin-right: 5px;
      }
    }
  }

  .luo-love-you{
    width: 402px;
    height: 85px;
    margin: auto;
    background: url('../assets/home/luo-love-you.png') no-repeat;
    background-size: 402px 85px;
    position: absolute;
    bottom: 11px;
    left: 50%;
    transform: translateX(-50%);
  }
}



.act-time {
  width: 699px;
  height: 86px;
  margin: auto;
  display: block;
}

.photo-show {
  width: 745px;
  height: 1712px;
  margin: auto;
  background: url('../assets/home/photo-show.png') no-repeat;
  background-size: 750px 1712px;
  padding-top: 433px;
  margin-top: 6px;

  .photo-list {
    width: 624px;
    height: 1215px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;

    .photo-empty-container {
      display: flex;
      flex-direction: column;
      height: 337px;
      margin: auto;
      margin-top: 113px;

      .photo-empty {
        width: 276px;
        height: 228px;
        margin: auto;
        background: url('../assets/home/photo-empty.png') no-repeat;
        background-size: 276px 228px;
      }

      .text {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #A07112;
        line-height: 37px;
        margin-top: 24px;
        text-align: center;
      }
    }

    .photo-item {
      width: 295px;
      height: 391px;
      background: rgba(255, 255, 255, 0.57);
      border-radius: 12px;
      opacity: 0.86;
      border: 3px solid #ECC996;
      margin-bottom: 13px;

      &:nth-child(odd) {
        margin-right: 19px;
      }

      .main-img {
        width: 261px;
        height: 303px;
        border-radius: 12px;
        margin: auto;
        display: block;
        margin-top: 17px;
        margin-bottom: 8px;
      }

      .user-img {
        width: 50px;
        height: 50px;
        border: 1px solid #C6C6C6;
        border-radius: 50%;
        margin-right: 12px;
        margin-left: 17px;
      }

      .user-name {
        max-width: 235px;
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 33px;
      }
    }
  }
}

.act-dialog {
  .img {
    width: 698px;
    height: 828px;
  }

  .close {
    width: 54px;
    height: 54px;
    background: url('../assets/icon-act-dialog-close.png') no-repeat;
    background-size: 54px;
    margin: auto;
    margin-top: -27px;
  }
}

.join-activity {
  width: 474px;
  height: 128px;
  background: url('../assets/home/join-activity.png') no-repeat;
  background-size: 474px 128px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.btn-group {
  position: fixed;
  right: 13px;
  bottom: 273px;

  .btn-item {
    width: 100px;
    height: 100px;
    background: linear-gradient(180deg, #F96161 0%, #F03232 100%);
    box-shadow: 0px 2 9px 0px rgba(0, 0, 0, 0.38), inset 3px 3 11px 0px rgba(96, 54, 30, 0.5);
    border: 3px solid #FFE4B0;
    border-radius: 50%;
    font-size: 24px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFE5AB;
    line-height: 30px;
    padding: 18px 22px;
    box-sizing: border-box;
    margin-top: 30px;
  }
}


.prize-show {
  width: 679px;
  height: 836px;
  background: #FEE5C6;
  box-shadow: 0px 2 8px 0px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  opacity: 0.9;
  border: 4px solid #ED461A;
  margin: auto;
  margin-top: 79px;

  .title {
    width: 335px;
    height: 105px;
    margin: auto;
    margin-top: -52.5px;
    display: block;
  }

  .desc {
    // width: 590px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
    text-align: center;
    margin-top: 12px;

    span {
      color: #FF1818;

    }
  }

  .prize-main-img {
    width: 548px;
    height: 677px;
    margin: auto;
    display: block;
    margin-top: 24px;
  }
}

.share-dialog {
  width: 750px;
  height: 318px;
  background: #FFFFFF;
  border-radius: 39px 39px 0px 0px;
  padding-top: 45px;

  .title {
    height: 48px;
    font-size: 34px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 48px;
    text-align: center;
    margin-bottom: 48px;
  }

  .icon-close {
    width: 44px;
    height: 44px;
    background: url('../assets/icon-close.png') no-repeat;
    background-size: 44px;
    position: absolute;
    right: 43px;
    top: 22px;
  }

  .share-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 86px;
      height: 86px;
    }

    .text {
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 33px;
      margin-top: 4px;
    }
  }
}

.share-success {
  width: 750px;
  height: 588px;
  background: #FFFFFF;
  border-radius: 39px 39px 0px 0px;
  padding-top: 86px;

  .title {
    height: 48px;
    font-size: 34px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 51px;
      height: 48px;
      margin-right: 8px;
    }
  }

  .icon-close {
    width: 44px;
    height: 44px;
    background: url('../assets/icon-close.png') no-repeat;
    background-size: 44px;
    position: absolute;
    right: 43px;
    top: 46px;
  }

  .main-img {
    width: 674px;
    height: 392px;
    margin: auto;
    display: block;
    margin-top: 44px;
  }
}

.share-guide-container{
  position: relative;
  width: 100%;
  height: 100%;
  .share-guide{
    position: absolute;
    width: 430px;
    height: 396px;
    top: 30px;
    right: 30px;
    background: url('../assets/home/share-guide.png') no-repeat;
    background-size: 430px 396px;

    .i-konw{
      width: 360px;
      height: 73px;
      margin: auto;
      margin-top: 300px;
      display: block;
    }
  }
}


</style>
