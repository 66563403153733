<template>
  <div class="page">
    <img class="banner" src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/activity-banner.png">
    <div class="rule-title">参与规则</div>
    <div class="rule-content">正月初十六，请大家将拥抱的照片合集发送至活动后台。我们将选出最具创意和感人的照片，进行线上和线下展览。</div>
    <div class="form-container">
      <div class="join-info"></div>
      <div class="input input-name">
        <van-field v-model="name" placeholder="姓名" />
      </div>
      <div class="input input-phone">
        <van-field v-model="phone" type="tel" placeholder="手机号" />
      </div>
      <div class="input input-upload">
        <van-uploader :preview-image="false" v-model="fileList" :after-read="afterImageRead">
          <div class="input-upload-content">
            <van-image v-if="fileUrl" class="input-upload-content-image" fit="cover" :src="fileUrl" />
            <template v-else>
              <van-row type="flex" align="center" justify="center">
                <div class="add-btn"></div>
              </van-row>
              <van-row type="flex" align="center" justify="center">
                <div class="add-text">上传图片（限传一张）</div>
              </van-row>
            </template>
          </div>
        </van-uploader>
      </div>
      <div class="submit-btn" @click="submitActivity"></div>
    </div>

    <!-- 提交成功弹窗 -->
    <van-popup v-model="showJoinSuccess" round position="bottom" :style="{ height: '25.57rem' }" @close="onJoinSuccessClose">
      <div class="join-success-dialog">
        <div class="icon-close" @click="closeDialog"></div>
        <div class="luo-love-you"></div>

        <van-row type="flex" align="center" justify="center">
          <img class="join-success-icon" src="../assets/icon-success.png" />
          <div>提交成功</div>
        </van-row>

        <template v-if="qrcode">
          <van-row type="flex" align="center" justify="center">
            <div class="qr-code-container">
              <img class="qr-code" :src="qrcode" />
            </div>
          </van-row>
          <div class="join-success-tips">长按扫一扫添加微信，了解最新活动进展</div>
        </template>

        <div class="get-paper" @click="getPaper"></div>
      </div>


    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { Field, Toast, ImagePreview } from 'vant';
import { Uploader } from 'vant';
import { uploadImage } from '../apis/user'
import { Popup } from 'vant';
import { Col, Row } from 'vant';
import { joinActivity } from '@/apis/user'
import { getAppConfig } from "@/apis/app";

// 全局注册
Vue.use(ImagePreview);

Vue.use(Col);
Vue.use(Row);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Field);
export default {
  name: "JoinActivity",
  props: {
  },
  data() {
    return {
      name: '',
      phone: '',
      fileList: [],
      fileUrl: '',
      showJoinSuccess: false,
      qrcode: ''
    }
  },
  methods: {
    async afterImageRead() {
      Toast.loading({
        overlay: true,
        message: '上传中',
      });
      const ret = await uploadImage(this.fileList[0].file);
      this.fileUrl = ret['path'];
      Toast.clear();
      // console.log('图片', `http://127.0.0.1:8000/storage/${ret['path']}`, this.fileList[0])
    },

    getPaper(){
      ImagePreview({
        images: [
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/1.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/2.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/3.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/4.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/5.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/6.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/7.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/8.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/9.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/10.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/11.jpeg',
        'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/wallpaper/12.jpeg',
      ],
      closeOnPopstate: false,
      closeable: true
    });
      Toast('长按可保存壁纸');
    },
    async submitActivity() {
      if (!this.name) {
        Toast('请填写姓名');
        return;
      }
      if (!this.phone) {
        Toast('请填写手机号');
        return;
      }
      if (!this.fileUrl) {
        Toast('请上传图片');
        return;
      }

      const namePattern = /^[A-Za-z\u4e00-\u9fa5]+$/;
      // 使用正则表达式测试输入字符串
      if (!namePattern.test(this.name)) {
        Toast('姓名只能为中文或字母');
        return;
      }

      // 中国大陆手机号正则表达式
      const phonePattern = /^1[3456789]\d{9}$/;
      // 使用正则表达式测试手机号
      if (!phonePattern.test(this.phone)) {
        Toast('请填写正确的手机号码');
        return;
      }
      let toast = Toast.loading();
      try {
        const ret = await joinActivity(this.name, this.phone, this.fileUrl);
        console.log('结果', ret)
        if (ret['code'] === 0) {
          getAppConfig().then(ret => {
            this.qrcode = ret.data.customer_service_wechat
          });
          this.showJoinSuccess = true;
        }
      } catch (error) {
        console.log(error)
      } finally {
        toast.clear()
      }
      // todo 提交活动

    },
    closeDialog() {
      this.showJoinSuccess = false;
    },
    onJoinSuccessClose() {
      this.$router.push('/');
    },

    savePicture(Url){
        var blob=new Blob([''], {type:'application/octet-stream'});
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = Url;
        a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0];
        var e = document.createEvent('MouseEvents');
        e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
        URL.revokeObjectURL(url);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  min-height: 100%;
  background: url('https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/activity_sub/bg.png') repeat;
  background-size: 100%;
  padding-top: 30px;
  padding-bottom: 49px;
}

.banner {
  width: 704px;
  height: 332px;
  background: #FFFFFF;
  border-radius: 24px;
  margin: auto;
  display: block;
}

.rule-title {
  /* width: 144px; */
  height: 43px;
  font-size: 36px;
  font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 43px;
  text-align: center;
  margin: auto;
  margin-top: 37px;
}

.rule-content {
  width: 693px;
  height: 80px;
  font-size: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 40px;
  margin: auto;
  margin-top: 23px;
  text-align: center;
}

.input {
  width: 610px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #FFB58F;
  margin: auto;
  overflow: hidden;
}

.input-name {
  margin-top: 103px;
  height: 80px;
}

.input-phone {
  margin-top: 30px;
  height: 80px;
}

.input-upload {
  height: 284px;
  margin-top: 30px;
}

.input-upload-content {
  width: 610px;
  height: 284px;
  background: #fff;
}

.input-upload-content-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.add-btn {
  width: 64px;
  height: 64px;
  background: url('../assets/icon-add.png') no-repeat;
  background-size: 64px;
  margin-top: 80px;
}

.add-text {
  width: 100%;
  height: 37px;
  font-size: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 37px;
  text-align: center;
  margin-top: 17px;
}

.submit-btn {
  width: 474px;
  height: 128px;
  background: url('../assets/submit-activity.png') no-repeat;
  background-size: 474px 128px;
  margin: auto;
  margin-top: 18px;
}

.join-success-dialog {
  width: 750px;
  height: 25.57rem;
  background: #FFFFFF;
  border-radius: 39px 39px 0px 0px;
  box-sizing: border-box;
  padding-top: 112px;
}

.join-success-icon {
  width: 51px;
  height: 48px;
  margin-right: 8px;
}

.qr-code-container {
  width: 350px;
  height: 350px;
  border-radius: 17px;
  border: 1px solid #D7D7D7;
  margin-top: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code {
  width: 328px;
  height: 328px;
}

.icon-close {
  width: 44px;
  height: 44px;
  background: url('../assets/icon-close.png') no-repeat;
  background-size: 44px;
  position: absolute;
  right: 43px;
  top: 53px;
}

.luo-love-you{
  width: 369px;
  height: 82px;
  background: url('../assets/luo-love-you.png') no-repeat;
  background-size: 369px 82px;
  margin: auto;
  margin-bottom: 41px;
}

.join-success-tips {
  width: 100%;
  height: 37px;
  font-size: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 37px;
  text-align: center;
  margin-top: 40px;
}

.get-paper{
  width: 400px;
  height: 104px;
  background: url('../assets/get-paper.png') no-repeat;
  background-size: 400px 104px;
  margin: auto;
  margin-top: 54px;
}

.form-container {
  position: relative;
  width: 679px;
  height: 772px;
  background: #FEE5C6;
  box-shadow: 0px 2 8px 0px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  opacity: 0.9;
  border: 4px solid #ED461A;
  margin: auto;
  margin-top: 82px;
}

.join-info {
  position: absolute;
  top: -52px;
  left: 50%;
  transform: translateX(-50%);
  width: 335px;
  height: 105px;
  background: url('../assets/join-info.png') no-repeat;
  background-size: 335px 105px;
}
</style>
